@import url("../../node_modules/swiper/swiper-bundle.min.css");

.swiper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;

  &-wrapper {
    cursor: grab;

    .swiper-container:not(.swiper-container-free-mode) &{
      transition: transform 600ms $ease!important;
    }

    .swiper-container.--dragging & {
      transition: none!important;
    }
  }

  &-slide {
    height: auto;
    transition: transform 600ms $ease!important;

    .--dragging & {
      //transform: scale(0.95)!important;
    }
  }

  &-navigation {
    order: 1;
    margin-top: 40px;
    @include flex(center, center);
    gap: 10px;

    @include responsive($tabletSmall) {
      gap: get-vw(10);
      margin-top: get-vw(80);
    }

    > * {
      position: relative;
      cursor: pointer;
      transition: opacity 240ms $easeOutQuad;
      width: 80px;
      aspect-ratio: 1/1;
      @include flex(center, center);

      @include responsive($tabletSmall) {
        width: get-vw(80);
      }

      @include noTouchHover() {
        &:before {
          transform: scale(0.9);
        }

        &:after {
          transform: scale(0.85);
        }
      }

      &:before, &:after {
        @include content();
        @include cover();
        background: $base;
        border-radius: 50%;
        transition: transform 420ms $ease;
        mix-blend-mode: difference;
      }

      &:after {
        background: $white;
        transform: scale(0);
      }

      &.prev {
        .arrow {
          transform: rotate(180deg);
        }
      }

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }
  }

  &-pagination {
    order: 3;
    position: relative!important;
    margin-top: 20px!important;
    bottom: 0px!important;
    @include flex(center, flex-end);

    > * {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: $base;
      cursor: pointer;
      transition: all 240ms $easeOutQuad;

      .--dark & {
        background: $grey-l;
      }

      @include noTouchHover() {
        transform: scale(1.1);
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.--active {
        background: $links-color;
      }
    }
  }

  &-scrollbar {
    order: 4;
    position: relative!important;
    left: 0%!important;
    width: 100%!important;
    margin-top: 20px;
    height: 2px!important;
    background: $grey-l;

    .--dark & {
      background: $base;
    }

    &-drag {
      position: absolute;
      top: 0px;
      background: $base;
      border-radius: 4px;
      cursor: pointer;

      .--dark & {
        background: $grey-l;
      }
    }
  }
}
