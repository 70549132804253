.Hero {
  position: relative;
  padding: 0;
  overflow: hidden;
  height: 100vh;

  > .wrapper {
    @include cover();
  }

  &__video {
    z-index: 20;
    pointer-events: none;
    @include cover();
    background: $base;

    video {
      @include cover();
      object-fit: cover;
    }
  }

  &__step {
    text-align: center;
    @include cover();
    z-index: 10;
    @include flex(center, center);
    padding: 60px 0;

    .container {
      @include responsive($tabletSmall) {
        padding: 0 space(1);
      }
    }

    .sentence {
      font-size: 14vw;
      line-height: 1;

      @include responsive($mobile) {
        font-size: 80px;
      }

      @include responsive($tabletSmall) {
        @include font-vw(80);
      }
    }

    p {
      font-family: $main-font;
      font-size: 40px;
      line-height: 1.1;

      @include responsive($tabletSmall) {
        @include font-vw(40);
        max-width: get-vw(360);
      }
    }

    &.--final {
      align-items: flex-end;

      @include responsive($tabletSmall) {
        text-align: left!important;
        align-items: center;
      }

      .--line {
        @include responsive($tabletSmall) {
          text-align: left!important;
        }
      }
    }
  }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -40%);
    min-width: 1200px;
    will-change: transform;

    > .wrapper {
      // transform-origin: 50% 38%;
      transform-origin: 50% 34%;
    }
  }

  .painting {
    position: relative;
    @include imgRatio(234, 317);
    // width: 16.05vw;
    width: 100%;
    min-width: 194px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    transform-origin: 50% 39.7%;
    will-change: transform;

    .visual__container {
      @include cover();
      object-fit: contain;
    }
  }
}