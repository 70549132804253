.Btn {
  position: relative;
  @include flex(center, center, inline-flex);
  text-align: center;
  padding: 12px 24px;
  margin-top: 40px;
  outline: 0;
  border: 0;
  cursor: pointer;
  mix-blend-mode: difference;
  overflow: hidden;

  @include responsive($tabletSmall) {
    padding: get-vw(15) get-vw(30);
    margin-top: get-vw(40);
  }

  @include noTouchHover() {
    &:before {
      transform: translateY(-100%);
    }

    .Btn__txt {
      transform: translateY(-150%);

      &:after {
        transform: translateY(50%); 
      }
    }
  }

  &__txt {
    position: relative;
    display: block;
    color: inherit;
    font-size: 24px;
    line-height: 1.2;
    position: relative;
    color: $white;
    mix-blend-mode: difference;
    transition: transform 420ms $ease;

    @include responsive($tabletSmall) {
      @include font-vw(24);
    }

    &:after {
      content : attr(title);
      position: absolute;
      top: 100%;
      left: 0;
      transform: translateY(150%);
      transition: transform 1200ms $ease;
    }
  }

  &:before {
    @include content();
    @include cover();
    background: $white;
    transition: transform 480ms $ease;
    transform-origin: top center;
  }

  &:after {
    @include content();
    @include cover();
    border: 2px solid $white;
  }
}