.Footer {
   background: $base;
   color: $white;
   position: relative;
   bottom:0;
   left:0;
   z-index:1;
   width:100%;

   .container {
      position: relative;
      z-index: 10;
      padding: 20px;
      padding-top: 40px;
      @include flex(center, space-between);
      gap: 20px;
      flex-direction: column;

      @include responsive($mobile) {
         padding: 40px;
         padding-top: 120px;
         flex-direction: row;
      }

      @include responsive($tabletSmall) {
         padding: get-vw(40);
         padding-top: get-vw(120);
      }
   }

   .Copyright {
      font-size: 20px;

      @include responsive($tabletSmall){
         @include font-vw(20);
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
       }
   }

   .Gradients {
      @include cover();
      height: 100%;
      opacity: 0.5;
   }
}
