.Components__page {
  overflow: hidden;

   #SwiperDemo {
     .swiper-slide {
       background: $grey-l;
       @include flex(center, center);
       height: 500px;

       .--dark & {
        background: $base;
       }

       h3 {
         margin: 0;
         font-weight: bold;
         text-transform: uppercase;
       }
     }
   }
   
   #Prllx {
     .visual__container {
       max-width: 800px;
       @include imgRatio(1600, 900, $grey-l);

       .--dark & {
        @include imgRatio(240, 120, $base);
       }
     }
   }
   
   #InView {
     .visual__container {
       max-width: 800px;
     }
     
     div[data-inview] {
       .visual__container {
         opacity: 0;
         transform: translate(20%, 150%) rotate(-20deg);
         transition: all 1200ms $ease;
         background: $grey-l;

         .--dark & {
          background: $base;
         }
       }
       
       &.--visible {
         .visual__container {
           opacity: 1;
           transform: translate(0%, 0%) rotate(0deg);
         }
       }
     }
   }
}
