$menuBreakpoint: $tablet;

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  &:before {
    @include content();
    @include cover();
    background: linear-gradient(0deg, rgba($base, 0) 0%, $base 100%);
  }

  &.--fixed {
    transition: transform 820ms $ease;

    &:not(.--show) {
      transform: translateY(-100%);
    }
  }

  .container {
    width: 100%;
    max-width: none;
    @include flex(center, flex-end);
    padding: 30px;

    @include responsive($tabletSmall) {
      padding: get-vw(40);
    }
  }

  .Brand {
    margin-right: auto;
    color: $white;
    pointer-events: all;
  }

  .Subscribe {
    margin: 0;
    pointer-events: all;
  }
}
