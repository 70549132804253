.App {
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  padding: 0 30px;
  position: relative;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 12);
  }
}

section {
  padding: 80px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(120) 0;
  }

  &.--w {
    background: $white;
    color: $base;
  }

  .headline {
    margin-bottom: 60px;

    @include responsive($tabletSmall) {
      margin-bottom: get-vw(80);
    }

    h2, h3 {
      margin: 0;
    }
    
    p {
      font-size: 20px;

      @include responsive($tabletSmall) {
        @include font-vw(20);
      }
    }
  }
}
