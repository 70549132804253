.Brand {
  display: block;
  position: relative;
  z-index: 10;
  pointer-events: all;
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.03em;

  @include responsive($tabletSmall){
    @include font-vw(40);
  }

  span {
    font-style: italic;
  }
}