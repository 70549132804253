.Advantages {
    overflow: hidden;

  .swiper-slide {
    max-width: 540px;

    @include responsive($tabletSmall){
        max-width: get-vw(540);
    }
  }
}