.Formule__card {
  background: $base;
  padding: 10px;
  @include flex(flex-start, flex-start);
  flex-direction: column;
  gap: 10px;

  .visual__container {
    @include imgRatio(380, 520);
  }

  .Btn {
    margin: 0;
    width: 100%;
  }

  .content {
    padding: 10px;

    @include responsive($tabletSmall) {
      padding: 0;
    }

    h3 {
      font-size: 40px;
      margin: 0;

      @include responsive($tabletSmall) {
        @include font-vw(40);
      }
    }

    span {
      display: block;
      font-size: 24px;

      @include responsive($tabletSmall) {
        @include font-vw(24);
      }

      &.price {
        font-size: 64px;

        @include responsive($tabletSmall) {
          @include font-vw(64);
        }
      }
    }
  }
}
