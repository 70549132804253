.Formules {
  position: relative;
  overflow: hidden;
  
  .headline {
    @include flex(flex-start, space-between);
    flex-direction: column;
    gap: 30px;

    @include responsive($tabletSmall){
      gap: space(1);
      flex-direction: row;
    }

    > * {
      width: 100%;
    }

    h2 {
      @include responsive($tabletSmall){
        max-width: space(8);
      }
    }
  }

  .swiper {
    @include responsive($tabletSmall){
      margin: 0 space(-1);
    }
  }

  .Gradients {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
  }
}