.Copyright {
   @include flex(center, center);

   > span {
     opacity: 0.4;
     transition: opacity 240ms $ease;
   }

   .wkn {
      margin-left: 5px;

      svg path {
       fill: $white;
      }

      &+span {
        margin-left: 5px;
        font-style: italic;
        font-family: serif;
      }
   }

   @include noTouchHover() {
     .wkn {
       opacity: 1;

       &+span {
         opacity: 1;
       }
     }
   }
}
