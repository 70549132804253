.Intro {
  @include cover(fixed);
  z-index: 2000;
  background: $base;
  visibility: hidden;
  overflow: hidden;

  html.--dark & {
    background: $base;
  }

  body.--animating & {
    visibility: visible;
  }

  &__video {
    @include cover();

    video {
      @include cover();
      object-fit: cover;
    }
  }
}
