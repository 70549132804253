.Socials {
  @include flex(center, center);
  gap: 14px;
  font-size: 20px;
  line-height: 1;

  @include responsive($tabletSmall) {
    @include font-vw(20);
  }

  a {
    display: block;
    position: relative;
    text-decoration: none!important;

    &:after {
      @include content();
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-bottom: 1px solid $white;
    }
  }
}