.Nav__toggle {
  position: relative;
  background: 0;
  border: 0;
  outline: 0;
  padding: 10px;
  z-index: 10;
  pointer-events: all;
  cursor: pointer;

  @include responsive($menuBreakpoint) {
    display: none;
  }

  .line {
    display: block;
    height: 2px;
    width: 20px;
    background: $base;
    border-radius: 6px;
    transform-origin: left center;
    transition: transform 400ms $easeOutQuad;

    &+.line {
      margin-top: 4px;
    }

    &:last-child {
      transform: scaleX(0.6);
    }

    body.showMenu & {
      @include responsive($menuBreakpoint, max) {
        background: $white;
      }
    }

    html.--dark & {
      background: $white;
    }
  }
}
