.arrow {
  position: relative;
  @include imgRatio(18, 14);
  width: 18px;

  @include responsive($tabletSmall) {
    width: get-vw(18);
  }

  svg {
    @include cover();
  }
}