.Modal {

  position: relative;
  background: $white;
  color: $base;
  @include rounded(20px);
  width: 100%;
  max-width: 790px;
  transform: translateY(75%) rotateX(-20deg);
  transition: transform 820ms $ease, opacity 420ms $ease;
  opacity: 0;

  @include responsive($tabletSmall) {
    max-width: get-vw(790);
  }

  .--showModal & {
    transform: translateY(0) rotateX(0deg);
    opacity: 1;
  }

  > .inner {
    padding: 60px 30px 30px;

    @include responsive($mobile) {
      padding: 80px 40px 40px;
    }

    @include responsive($tabletSmall) {
      padding: get-vw(80);
    }
  }

  &__panel {
    @include cover(fixed);
    z-index: 1000;
    pointer-events: none;

    .--showModal & {
      pointer-events: all;
    }

    .Close {
      position: absolute;
      top: 20px;
      right: 20px;
      background: 0;
      border: 0;
      padding: 0;
      @include flex(center, center);
      width: 40px;
      height: 40px;
      z-index: 10;
      cursor: pointer;
      transition: transform 350ms $easeInOutQuad;

      @include responsive($tabletSmall) {
        width: get-vw(40);
        height: get-vw(40);
        top: get-vw(20);
        right: get-vw(20);
      }

      @include noTouchHover(){
        transform: scale(0.75) rotate(-180deg)
      }

      svg > * {
        stroke: $base;
      }
    }
  }

  &__wrapper {
    @include cover();
    overflow: auto;
    max-height: 100vh;
    padding: 10px;
    @include flex(flex-start, center);
    perspective: 800px;

    @include responsive($mobile) {
      padding: 40px;
    }

    @include responsive($tabletSmall) {
      padding: get-wv(40);
    }
  }
}
