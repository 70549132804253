.BlockTextVisual {

  .container {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    gap: 120px;

    @include responsive($tabletSmall) {
      gap: space(3);
    }

    > * {
      width: 100%;
    }
  }

  .BlockTextVisual__block {
    @include flex(stretch, space-between);
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;

    @include responsive($tabletSmall) {
      flex-direction: row;
      gap: space(2);
    }

    &:nth-child(odd) {
      @include responsive($tabletSmall) {
        gap: space(1.5);
      }

      .image {
        @include responsive($tabletSmall) {
          order: 2;
        }
      }
    }

    > * {
      width: 100%;
    }

    .content {
      @include flex(center, flex-start);

      @include responsive($tabletSmall) {
        padding: get-vw(60) 0;
      }
    }

    .image  {
      position: relative;

      &[data-inview] {
        &:not(.--visible) {
          .visual__container {
            transition: none;
            opacity: 0;
            filter: brightness(0);
          }

          .lines .line {
            transition: none;
            &:nth-child(1), &:nth-child(3) { transform: scaleX(0);}
            &:nth-child(2), &:nth-child(4) { transform: scaleY(0);}
          }
        }
      }

      @include responsive($tabletSmall) {
        max-width: space(8);
      }
      
      .visual__container {
        min-height: 75vh;
        height: 100%;
        transition: all 1200ms $ease 240ms;
      }

      .lines {
        @include cover();
        z-index: -1;
      }
    
      .line {
        position: absolute;
        transition: transform 640ms $ease;
    
        &:nth-child(1) {
          top: 0;
          left: 0;
          width: 100%;
          border-top: 3px solid $white;
          transform-origin: left center;
        }
    
        &:nth-child(2) {
          top: 0;
          right: 0;
          height: 100%;
          border-right: 3px solid $white;
          transition-delay: 120ms;
          transform-origin: center top;
        }
    
        &:nth-child(3) {
          bottom: 0;
          right: 0;
          width: 100%;
          border-bottom: 3px solid $white;
          transition-delay: 120ms;
          transform-origin: right center;
        }
    
        &:nth-child(4) {
          bottom: 0;
          left: 0;
          height: 100%;
          border-left: 3px solid $white;
          transition-delay: 240ms;
          transform-origin: bottom center;
        }
      }
    }
  }
}