.Gradients {
  position: relative;
  z-index: -1;
  @include flex(stretch, center);
  height: 200px;

  &__line {
    flex: 1;
    flex-grow: 2;
    margin-left: -1px;
    margin-bottom: -1px;
  }
}