.Advantage__card {
  border-left: 1px solid $base;
  padding: 0 40px;
  min-height: 100%;

  @include responsive($tabletSmall){
    padding: 0 get-vw(100);
  }

  .swiper-slide:first-child & {
    border-left: 0;
  }

  .headline {
    @include flex(flex-start, flex-start);
    gap: 20px;
    margin-bottom: 20px;

    @include responsive($tabletSmall){
      gap: get-vw(20);
      margin-bottom: get-vw(20);
    }
  }

  .icon {
    max-width: 40px;
    min-width: 40px;

    @include responsive($tabletSmall){
      max-width: get-vw(40);
      min-width: get-vw(40);
    }
  }

  h3 {
    font-size: 40px;
    padding-right: 10%;

    @include responsive($tabletSmall){
      @include font-vw(40);
    }
  }

  p {
    font-size: 12px;
  }
}
