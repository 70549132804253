h1, h2, h3, h4, h5, h6{
   font-weight: 400;
   line-height: 1;
}

h1 {
   line-height: 1.05;
   margin-bottom: 0;
   font-size: 16vw;

   @include responsive($tabletSmall) {
      @include font-vw(100);
   }

   .subtitle + & {
      margin-top: 0.1em;
   }

   > .--oh{
      &:not(:first-child){
         margin-top: -0.15em;
      }
   }
}

h2 {
   font: 400 14vw $main-font;
   line-height: 1;
   margin-bottom: 40px;

   @include responsive($tabletSmall){
     @include font-vw(96);
     margin-bottom: get-vw(40);
   }
}

h3 {
   font: 400 46px $main-font;
   line-height: 1;
   margin-bottom: 30px;

   @include responsive($tabletSmall){
     @include font-vw(76);
     margin-bottom: get-vw(30);
   }
}

h4 {
   font: 400 22px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(22);
   }
}

h5 {
   font: 400 20px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(20);
   }
}

h6 {
   font: 400 18px $main-font;
   margin-bottom: 1em;

   @include responsive($tabletSmall){
     @include font-vw(18);
   }
}

sup {
   vertical-align: top;
   filter: opacity(0.95);

   @include responsive($tabletSmall, max) {
      font-size: 50px;
   }

   @include responsive($tabletSmall) {
      @include font-vw(50);
   }
}

.subtitle {
   filter: opacity(0.5);
   letter-spacing: -0.03em;
   font-size: 20px;

   @include responsive($tabletSmall) {
      @include font-vw(20);
   }
}

strong {
   font-weight: 500;
}

p {
   font-family: $second-font;
   font-size: 16px;
   line-height: 1.6;

   @include responsive($tabletSmall){
     @include font-vw(16);
   }

   &.no__results {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      opacity: 0.2;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }
   }
}

.wswyg--content {
   text-align: left;

   * { color: inherit; }

   > * + * {
      margin-top: 30px;

      @include responsive($tabletSmall){
         margin-top: get-vw(30);
      }
   }

   h2 {
      font-size: 46px;

      @include responsive($tabletSmall){
         @include font-vw(46);
      }
   }

   ul,
   ol {
      padding-left: 30px;

      @include responsive($tabletSmall){
         padding-left: space(1);
      }

      > li + li {
         margin-top: 1em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(1) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 30px;
      border-left: 2px solid $grey;

      @include responsive($tabletSmall){
         padding-left: space(0.5);
      }

      p{
         font-weight: 500;
         font-size: 28px;
         line-height: 1.4;
         
         @include responsive($tabletSmall){
            @include font-vw(32);
         }
      }
   }

   a {
      color: $links-color;
      font-weight: bold;
      text-decoration: underline;
   }
}
