.Application {
  text-align: center;
  overflow: hidden;

  .content {
    position: relative;
    z-index: 10;
    margin: 0 auto;

    @include responsive($tabletSmall){
        max-width: space(12);
    }

    h2 {
        @include responsive($tabletSmall){
            padding: 0 5%;
        }
    }
  }

  &__visual {
    position: relative;
    margin: -80px -30px 0;
    pointer-events: none;

    @include responsive($tabletSmall){
      margin: space(-2) space(-2) 0;
    }

    &.from--top:not(.--visible) {
      .mobile__visual {
        transform: translate(-45%, -25%) rotate(6deg);
      }
    }

    .mobile__visual {
      position: absolute;
      @include imgRatio(1560, 1358);
      width: 320px;
      top: 50%;
      left: 50%;
      transform: translate(-45%, -55%) rotate(0);
      z-index: 20;
      transition: transform 1800ms $ease;

      @include responsive($tabletSmall){
        width: get-vw(620);
      }

      img {
        @include cover();
      }
    }

    .background {
      position: relative;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      min-width: 800px;
    }

    &:after {
      @include content();
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(0deg, $base 0%, rgba($base, 0) 100%);
      height: 10vh;
      z-index: 10;
    }
  }
}