.BlockText{
  .container {
    @include flex(flex-start, space-between);
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 40px;

    @include responsive($tabletSmall) {
      flex-direction: row;
      gap: space(1);
    }

    > * {
      width: 100%;
    }

    .headline {
      margin: 0;
      
      @include responsive($tabletSmall) {
        max-width: space(8);
      }
    }

    .content {
      p {
        font-size: 20px;
        line-height: 1.4;

        @include responsive($tabletSmall) {
          @include font-vw(20);
        }
      }
    }
  }
}