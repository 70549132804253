.visual {
  @include cover();
  transform: scale(1.02);
  transform-origin: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  will-change: transform;

  video {
    z-index: 1;
    @include cover();
    object-fit: cover;
  }

  img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    filter: saturate(0);

    &.--plchldr {
      @include cover();
      z-index: 0;
      -webkit-filter: blur(30px);
		  filter: blur(30px);
      transform: scale(1.2);
    }
  }

  &__container {
    position: relative;
  	overflow: hidden;
  	width: 100%;

  	&:before {
  		@include content();
  	}
  }
}
